export const extractSocialMetadata = (data) => {
  if (!data) {
    return [false, {}];
  }

  const metadata = {};

  data.map((slice) => {
    switch (slice.type) {
      case "general_card":
        metadata[slice.type] = {
          title: slice.primary.title[0].text,
          description: slice.primary.description[0].text,
          image: slice.primary.image ? slice.primary.image.url : "",
        };
        break;
      case "twitter_card":
        metadata[slice.type] = {
          title: slice.primary.title[0].text,
          description: slice.primary.description[0].text,
          image: slice.primary.image ? slice.primary.image.url : "",
          handle: slice.primary.twitter_handle,
        };
        break;
    }
  });

  return [Object.keys(metadata).length > 0, metadata];
};

export const makeAbsolute = (link) => {
  if (!link) {
    return null;
  }

  // external or already rooted
  if (link.indexOf("http") === 0 || link.indexOf("/") === 0) {
    return link;
  }

  return `/${link}`;
};
