import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useRecoilValue } from 'recoil';

import { viewportState, browserState } from '../ScrollAware';

const Container = styled.div`
  position: ${props => props.isStatic ? 'static' : 'fixed'};
  top: 0;
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;

  /* ${props => props.width && `max-width: ${props.width}px`}; */

  ${props =>
    props.index &&
    css`
      z-index: ${props.index};
    `}
`;

const Spacer = styled.div`
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: ${props => props.theme.zIndex.bottom};
`;

const FixedBlock = ({ children, index, isStatic = false }) => {
  const { isIE } = useRecoilValue(browserState);
  const { width } = useRecoilValue(viewportState);

  return (
    <>
      <Container index={index} isStatic={isStatic} width={isIE ? width - 100 : null}>{children}</Container>
      {!isStatic && <Spacer />}
    </>
  );
}

FixedBlock.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number,
  isStatic: PropTypes.bool,
};

export default FixedBlock;
