import React, { useRef } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { useRecoilValue } from "recoil";

import { browserState } from "./ScrollAware";
import close from "../../images/icons/close.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${(props) => props.theme.zIndex.top};
  background: ${(props) => props.theme.colors.black01};

  .react-player {
    position: relative;

    video {
      object-fit: ${(props) => props.objectFit || "cover"};
    }
  }
`;

const StopButton = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 30px;
  right: 30px;
  cursor: pointer;

  background-image: url(${close});
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  display: block;

  @media (min-width: ${(props) => props.theme.desktop}px) {
    top: 100px;
    right: 40px;
  }
`;

const FullScreenVideo = ({
  video,
  onClick,
  playing = false,
  objectFit
}) => {
  const browserWindow = typeof window !== "undefined" ? window : undefined;
  const el = useRef(browserWindow && document.body);

  if (!playing) {
    return null;
  }

  return createPortal(
    <Container objectFit={objectFit}>
      <ReactPlayer
        url={video.url}
        className="react-player"
        width="100%"
        height="100%"
        playing
        onEnded={onClick}
        loop
        controls
      />
      <StopButton onClick={onClick} />
    </Container>,
    el.current
  );
};

FullScreenVideo.propTypes = {
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  playing: PropTypes.bool,
};

export default FullScreenVideo;
