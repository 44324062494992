import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import { browserState, clipState, viewportState, getWindowScroll } from "./ScrollAware";

const Container = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.containerWidth}px;
  height: 100vh;
  z-index: ${props => props.theme.zIndex.swipe};
  pointer-events: none;
  transform: translateX(${props => props.initialScroll}px);
`;

const Polygon = styled.polygon`
  fill: ${props =>
    props.grey ? props.theme.colors.grey04 : props.theme.colors.white};
  transform: translateX(${props => props.initialScroll}px);
`;

const Swipe = ({
  id = "overlay-shape",
  grey = false,
  notch = true,
  delay = 0,
}) => {
  const [initialScroll, setInitialScroll] = useState(0);
  const { width, height } = useRecoilValue(viewportState);
  const { isIE } = useRecoilValue(browserState);
  const {
    points,
    containerWidth,
    translateX,
    multiplier,
    scrollMax,
  } = useRecoilValue(clipState);
  const swipeRef = useRef();
  let {current: scrollX} = useRef(0);
  let {current: ticking} = useRef(false);
  let scrollOffset = notch ? translateX : translateX - 142;

  const calcScrollX = () => {
    const scrollY = Math.max(0, getWindowScroll().y - delay);

    return Math.min(scrollY * multiplier, scrollMax);
  };

  useEffect(() => {
    const x = calcScrollX();
    setInitialScroll(x + scrollOffset);
  }, [multiplier, scrollOffset, delay]);

  const onScroll = () => {
    const scrollX = calcScrollX() + scrollOffset;

    swipeRef.current.style.transform = `translateX(${scrollX}px)`;
  };

  useEffect(() => {
    if (!swipeRef.current) {
      return;
    }

    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [swipeRef, multiplier, onScroll, delay]);

  if (isIE) {
    return (
      <Container containerWidth={containerWidth} ref={swipeRef} initialScroll={initialScroll}>
        <Polygon
          x="0"
          y="0"
          points={points}
          grey={grey}
          initialScroll={0}
        />
      </Container>
    );
  }

  return (
    <Container containerWidth={containerWidth} initialScroll={0}>
      <defs>
        <clipPath id={id}>
          <Polygon
            x="0"
            y="0"
            ref={swipeRef}
            points={points}
            grey={grey}
            initialScroll={initialScroll}
          />
        </clipPath>
      </defs>
    </Container>
  );
};

Swipe.propTypes = {
  id: PropTypes.string,
  grey: PropTypes.bool,
  notch: PropTypes.bool,
  delay: PropTypes.number,
};

export default Swipe;
